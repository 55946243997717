import { useState } from "react";
import axios from "../services/api";

const useTelefone = () => {
  const token = 'Bearer ' + sessionStorage.getItem('token_sac');
  const [loading, setLoading] = useState(true);
  const [telefones, setTelefones] = useState([]);

  const getTelefones = async (id) => {
    var config = {
      headers: {
        'Authorization': token
      },
    };

    const response = await axios.get(`/clientes/${id}/telefones`, config)
    setTelefones(response.data);
    setLoading(false)
  }

  return { getTelefones, telefones, loading }
}
export default useTelefone;