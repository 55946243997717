import React, { useEffect, useState } from "react";
import { ContainerPages } from "../../components/container";
import { getTheme, heightScreen } from "../../utils/theme";
import { Box, ButtonGroup, Paper, Stack } from "@mui/material";
import { StackJustify } from "../../components/stack";
import { ZoomIcon, Add, Settings, EditIcon, ListIcon, TreeBox } from "../../components/icons";
import { ButtonLinkIcon, ButtonToolTip } from "../../components/button";
import { Title, TitleGrupos, TitleSmall } from "../../components/typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { listaLength } from "../../utils/string";
import { iniFaq } from "../../utils/constructor";
import useFaq from "../../hooks/useFaq";
import { LayoutEmail } from "../../components/layout";
import useRastreios from "../../hooks/useRastreios";
import useUsuarios from "../../hooks/useUsuarios";
import { GeralContext } from "../../context";
import WarningIcon from '@mui/icons-material/Warning';
import { filtrarLista } from '../../utils/geral';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { DividerBasic } from '../../components/divider';
import { FAQDialog } from "../../components/dialog";
import { getPerm, getUser } from "../../utils/user";
import { BoxFaq } from "../../components/box";
import { useParams } from "react-router-dom";
import { getSession, setSession } from "../../utils/storage";

export default function ListFaq() {
    const { faqMenu, getFAQMenu, loadingFAQ, getFaq, faq, getFaqAprovados, listFaqApro } = useFaq();
    const [at, setAt] = React.useState(false);
    const { getRastreios, rastreios } = useRastreios();
    const [faqSelect, setFaqSelect] = React.useState(iniFaq);
    const { getListUsuarios } = useUsuarios();
    const [open, setOpen] = React.useState(false)
    const { listUsers } = React.useContext(GeralContext);
    const [exibirTodos, setExibirTodos] = useState(false);
    const { codigo } = useParams();
    let listExpanded = getSession('menuExpandedFaq') ? JSON.parse(getSession('menuExpandedFaq')) : ["1", "2"];

    useEffect(() => {
        getFAQMenu();
        getRastreios();
        setAt(false);
        getListUsuarios();
    }, [at === true])


    useEffect(() => {
        if (codigo)
            getFaq(codigo)
    }, [])

    useEffect(() => {
        setFaqSelect(faq)
        setElemento(faq)
    }, [faq])

    useEffect(() => {
        if (exibirTodos)
            getFaqAprovados();
    }, [exibirTodos])


    function setElemento(faq) {

        let descr = getTheme() === 'light' ? faq.descricao.replaceAll('(241, 241, 241)', '(51, 51, 51)')
            : faq.descricao.replaceAll('(51, 51, 51)', '(241, 241, 241)');

        descr = getTheme() === 'light' ? descr.replaceAll('#f4f5fa', '#343944')
            : descr.replaceAll('#343944', '#f4f5fa');
        descr = descr.replaceAll('http://10.1.1.27:3000', `https://${window.location.hostname}`);

        document.getElementById('conteudo').innerHTML = descr;
        setFaqSelect(faq)
    }

    const selecionarFaq = (id, idMenu = '0') => {
        if (listExpanded.indexOf(idMenu.toString()) > 0) {
            listExpanded = listExpanded.filter(value => value !== idMenu.toString());
        } else {
            listExpanded.push(idMenu.toString());
        }
        setSession('menuExpandedFaq', JSON.stringify(listExpanded))
        if (id > 0) {
            getFaq(id);
        } else {
            setElemento(iniFaq);
        }
    }


    function ListarItens(item) {
        let child = faqMenu.filter(it => it.master === item.codigo);
        return <TreeItem key={item.codigo} nodeId={item.codigo + ''} label={<TitleGrupos title={item.descricao} />} onClick={() => selecionarFaq(item.idFAQ, item.codigo)} >
            {child.length === 0 ? "" : child.map(ListarItens)}
        </TreeItem>
    }

    function DetalhesFaq() {
        let listaRastreio = []
        listaRastreio.push(filtrarLista(rastreios, 'codigo', faqSelect.rastreio)[0]);
        if (listaLength(listaRastreio) > 0) {
            for (let i = 0; i < listaLength(listaRastreio); i++) {
                if (listaRastreio[i].master && listaRastreio[i].master > 0) {
                    listaRastreio.push(filtrarLista(rastreios, 'codigo', listaRastreio[i].master)[0]);
                }
            }
        }

        function ListDescrRastreio(r) {
            return <Stack key={r.codigo} direction='row' alignItems='center'>
                {r.master === 0 ? '' : <KeyboardArrowRightIcon color='iconColor' />}
                <TitleSmall title={r.descricao} />
            </Stack>
        }

        return <Box>
            <Title title={faqSelect.titulo} />
            <Stack direction='row' alignItems='center' pt={1}>{listaLength(listaRastreio) > 0 ? listaRastreio.map(ListDescrRastreio).reverse() : ''}</Stack>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                {faqSelect.criadopor && listUsers ? <TitleSmall title={`Criado por ${filtrarLista(listUsers, 'codigo', faqSelect.criadopor)[0].login}`} /> : ''}
                {faqSelect.aprovadopor && listUsers ? <TitleSmall title={faqSelect.aprovadopor > 0 ? `Aprovado por ${filtrarLista(listUsers, 'codigo', faqSelect.aprovadopor)[0].login}` : ''} /> : ''}
                <Box>
                    {getPerm('LI') || (faqSelect.criadopor === parseInt(getUser().sub)) ? <ButtonLinkIcon title='Editar' color='blue' icon={<EditIcon color='blue' />} to={`/faq/edit/${faqSelect.codigo}`} /> : ''}
                    <ButtonToolTip title='Ampliar' color='iconColor' icon={<ZoomIcon />} onClick={() => setOpen(true)} />
                </Box>
            </Stack>
        </Box >
    }

    function SelecionarFaq(faq) {
        setFaqSelect(faq)
        setElemento(faq)
    }

    function ListarFaq(item) {
        return <BoxFaq onClick={() => SelecionarFaq(item)} faq={item} />
    }

    return (
        <ContainerPages activeAuxBar={true} title='FAQ'
            btnAux={<>
                <ButtonLinkIcon title='Novo FAQ' icon={<Add color='green' />} to='/faq/create' />
                <ButtonLinkIcon title='Pendentes' icon={<WarningIcon color='yellow' />} to='/faq/pend' />
            </>}
        >
            <LayoutEmail
                colL={
                    <Paper elevation={0} sx={{ padding: '5px 10px 5px 10px' }}>
                        <StackJustify>
                            <Title title={exibirTodos ? 'FAQs' : 'Menu'} />
                            {getPerm('LI') ? <ButtonGroup sx={{ width: '100%', justifyContent: 'end' }}>
                                {exibirTodos ? '' : <ButtonLinkIcon title='Config. Menu' icon={<Settings />} to='/faq/config' />}
                                {exibirTodos ?
                                    <ButtonToolTip title='Menu' color='iconColor' icon={<TreeBox />} onClick={() => setExibirTodos(false)} />
                                    :
                                    <ButtonToolTip title='Lista' color='iconColor' icon={<ListIcon />} onClick={() => setExibirTodos(true)} />
                                }

                            </ButtonGroup> : ''}
                        </StackJustify>
                        {exibirTodos ?
                            listaLength(listFaqApro) > 0 ? listFaqApro.map(ListarFaq) : <Title title='Não existem FAQs a serem listados.' />
                            :
                            <>
                                <TreeView
                                    aria-label="file system navigator"
                                    defaultCollapseIcon={<ExpandMoreIcon color='iconColor' />}
                                    defaultExpandIcon={<ChevronRightIcon color='iconColor' />}
                                    defaultExpanded={listExpanded}
                                >
                                    {loadingFAQ ? '' : faqMenu.filter(it => it.master === 0).map(ListarItens)}
                                </TreeView>
                            </>
                        }
                    </Paper>
                }
                colR={<>
                    {faqSelect.codigo > 0 ?
                        <>
                            <DetalhesFaq />
                            <DividerBasic />
                        </>
                        : ''}
                    <div id='conteudo'
                        style={{
                            color: getTheme() === 'dark' ? '#f4f5fa' : '#343944',
                            padding: '10px',
                            marginTop: '10px',
                            fontSize: '12px',
                            textAlign: 'justify'
                        }}>
                        <img src='./app-assets/img/logo/Logo.svg' alt='logo redsis' style={{ height: heightScreen(-200), width: '100%', opacity: '0.1', WebkitFilter: 'grayscale(100%)', filter: 'grayscale(100%)' }} />
                    </div>
                </>
                }
            />
            <FAQDialog open={open} descricao={faqSelect.descricao} onClose={() => setOpen(false)} />
        </ContainerPages >
    );
}