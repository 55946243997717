import React, { useContext } from 'react';
import { NavBar, NavBarSimple } from '../appbar';
import Container from '@mui/material/Container';
import SearchBar from '../search';
import { GeralContext } from '../../context';
import AuxBar from '../auxbar';
import SnackbarAlert from '../snackbar';
import { Paper } from '@mui/material';
import { LayoutEmail } from '../layout';

export function ContainerPages(props) {
    const { ativarPesquisa, mensagem, screen } = useContext(GeralContext);

    return (
        <Container maxWidth='xxl' style={{ margin: '0', padding: '0', height: screen.height, width: screen.width }}>
            <div style={{ height: '60px', marginBottom: '20px' }}>
                {props.navBarSimple ? <NavBarSimple /> : <NavBar />}
            </div>
            {ativarPesquisa ?
                <div style={{ height: '60px', padding: '0 10px' }}>
                    <SearchBar />
                </div> : ''}
            {props.activeAuxBar ?
                <div style={{ height: '60px', padding: '0 10px' }}>
                    <AuxBar title={props.title} btnAux={props.btnAux} toVoltar={props.toVoltar} ocultarBotoes={props.ocultarBotoes} />
                </div>
                : ''}
            <div style={{ height: `${(screen.height - 100) - (ativarPesquisa ? 60 : 0) - (props.activeAuxBar ? 60 : 0)}px`, padding: '0 5px', overflow: props.overflow && !props.ativarLogo ? 'auto' : '' }}>
                {props.ativarLogo && window.innerWidth > 768 ?
                    <LayoutEmail
                        colL={<Paper sx={{ height: `${(screen.height - 100) - (ativarPesquisa ? 60 : 0) - (props.activeAuxBar ? 60 : 0)}px`, textAlign: 'center' }} elevation={1}>
                            <img src='../../../../../../app-assets/img/logo/Logo.svg' alt='logo redsis' style={{ height: '100%', width: '100%', opacity: '0.4', WebkitFilter: 'grayscale(100%)', filter: 'grayscale(100%)' }} />
                        </Paper>}
                        colR={props.children} />
                    :
                    props.children
                }
            </div>
            <SnackbarAlert msg={mensagem} />
        </Container>
    );
}

export function SimpleContainerRedsis(props) {
    const { screen } = useContext(GeralContext);

    return (
        <Container maxWidth='xxl' style={{ margin: '0', padding: '0', height: screen.height, width: screen.width }}>
            {props.children}
        </Container>
    )

}