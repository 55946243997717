import * as React from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Check, HighLigth, SearchIcon, SearchOff } from '../icons';
import { GeralContext, SacContext } from '../../context';

export function ButtonIcon(props) {
    return (
        <Button variant="contained" type={props.type} size='small' startIcon={props.icon} color={props.color} fullWidth={props.fullWidth} onClick={props.onClick} onClickCapture={props.onClickCapture} disabled={props.disabled} style={props.style}>
            {props.caption}
        </Button>
    );
}

export function ButtonPesquisar() {
    const { ativarPesquisa, toggleAtivarPesquisa } = React.useContext(GeralContext);

    return <HtmlTooltip title={ativarPesquisa ? 'Fechar pesquisar' : 'Pesquisar'}>
        <IconButton
            aria-label="pesquisa" variant='contained' size='large' onClick={toggleAtivarPesquisa}
        >
            {ativarPesquisa ? <SearchOff fontSize='25px' /> : <SearchIcon fontSize='25px' />}
        </IconButton>
    </HtmlTooltip >
}

export function ButtonFechar() {
    const { toggleAcao, codigo } = React.useContext(SacContext);
    return (
        <ButtonIcon icon={<HighLigth color='white' />} caption='Fechar' onClick={() => toggleAcao(codigo, 'sac')} color='red' style={{ color: 'white' }} />
    );
}

export function LinkMenu(props) {
    return (
        <Link target={props.target} to={props.to} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }} color="red">{props.caption}</Link>
    );
}

export function ButtonConcluido(props) {
    return (
        <Button style={{padding: '5px'}} size='small' color='green' variant='contained' endIcon={<Check />} onClick={props.onClick} disabled={props.disabled} type={props.type}>Concluído</Button>
    );
}

export function ButtonCancelar() {
    const { toggleAcao, codigo } = React.useContext(SacContext);
    return (
        <ButtonIcon icon={<HighLigth color='white' />} caption='Cancelar' onClick={() => toggleAcao(codigo, 'sac')} color='red' style={{ color: 'white' }} />
    );
}

export function ButtonToolTip(props) {
    return (<>
        <HtmlTooltip title={props.title}>
            <IconButton
                id={props.id}
                aria-label={props.arialLabel}
                onClick={props.onClick}
                color={props.color}
                aria-controls={props.ariaControls}
                aria-haspopup={props.ariaHaspopup}
                aria-expanded={props.ariaExpanded}
                type={props.type}
                onChange={props.onChange}
                component={props.component}
            >
                {props.icon}
            </IconButton>
        </HtmlTooltip >
    </>);
}

export function ButtonLinkIcon(props) {
    return (<Link target={props.target} to={props.to} style={{ textDecoration: 'none', color: 'inherit', marginRight: '0px' }}>
        <HtmlTooltip title={props.title}>
            <IconButton
                id={props.id}
                onClick={props.onClick}
            >
                {props.icon}{props.caption}
            </IconButton>
        </HtmlTooltip >
    </Link>);
}

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.90)',
        maxWidth: '170px',
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #dadde9',
    },
}));