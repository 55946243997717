import * as React from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import useTipos from '../../hooks/useTipos';
import { agruparTags, colorDetail, colorSac, getListSetores, getSetor } from '../../utils/sac';
import { AccordionAtendimentos, AccordionGrupos, AccordionSubGrupos } from './../../components/accordion';
import { Box, CircularProgress, Grid, Paper, Stack } from '@mui/material';
import { getLayout, getTheme, heightScreen } from '../../utils/theme';
import { listaLength } from '../../utils/string';
import { GeralContext, SacContext } from '../../context';
import { HeaderSac } from '../../components/sac/header';
import { BodySac } from '../../components/sac/body';
import LayoutSac from '../layout';
import { filtrarLista, orderList } from '../../utils/geral';
import useMovimento from '../../hooks/useMovimentos';
import { FAQDialog, SacDialog } from '../dialog';
import { BoxAtendimentoTC, BoxFaq, BoxGruposTC, BoxSubGruposTC } from '../box';
import useFaq from '../../hooks/useFaq';
import useRastreios from '../../hooks/useRastreios';
import useUsuarios from '../../hooks/useUsuarios';
import { iniFaq } from '../../utils/constructor';
import { Title, TitleSmall } from '../typography';
import { getPerm, getUser } from '../../utils/user';
import { DividerBasic } from '../divider';
import { ButtonLinkIcon, ButtonToolTip } from '../button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Add, Delete, EditIcon, Thumb, ZoomIcon } from '../../components/icons';

export function TabSetor() {
    const [value, setValue] = React.useState(getSetor());
    const { getNome } = useTipos();
    const { listSac, toggleSetor, sac, codigo, open, toggleOpen, toggleCodigo, loading } = React.useContext(SacContext);
    const { pesquisar, ordemSac, toggleAtualizar } = React.useContext(GeralContext);
    const { getMovimentos, listMovimento, putMovimentoAt } = useMovimento();
    const lista = listSac;
    let qtde = 0;

    React.useEffect(() => {
        getMovimentos(undefined, true);
    }, [])

    function drag(ev, sourceColumnId) {
        ev.dataTransfer.setData("text", ev.target.id);
        ev.dataTransfer.setData("sourceColumnId", sourceColumnId);
    }

    const allowDrop = (ev) => {
        ev.preventDefault();
    }

    const drop = async (ev) => {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        var idSac = data;
        var destino = ev.target.id;
        if (destino !== '' || destino !== null || destino !== 'null')
            await putMovimentoAt(idSac, destino);
        toggleAtualizar(true)
    }

    function showSac() {
        toggleOpen(true);
    }

    const handleClose = () => {
        toggleOpen(false);
    };

    function ListarColunas(grupo, g) {
        return <BoxGruposTC key={g} id={grupo.codigo} grupo={grupo.grupo} registros={grupo.total} onDrop={(e) => drop(e, grupo.grupo)} onDragOver={(e) => allowDrop(e)}>
            {listaLength(grupo.grupos) > 0 ? grupo.grupos.map(ListSubColunas) : listaLength(grupo.atendimentos) > 0 ? grupo.atendimentos.map(ListarItens) : ''}
        </BoxGruposTC>
    }

    function ListSubColunas(subGrupo, s) {
        return <BoxSubGruposTC key={s} id={subGrupo.grupo} grupo={subGrupo.grupo} registros={subGrupo.total} onDrop={(e) => drop(e, subGrupo.grupo)} onDragOver={(e) => allowDrop(e)}>
            {listaLength(subGrupo.atendimentos) > 0 ? subGrupo.atendimentos.map(ListarItens) : ''}
        </BoxSubGruposTC>
    }

    function ListarItens(atendimento) {
        return <BoxAtendimentoTC key={atendimento.codigo} atendimento={atendimento} onDragStart={(e) => drag(e, atendimento.tags)} onDoubleClick={() => showSac()} onClick={() => toggleCodigo(atendimento.codigo)} />;
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        qtde = 0;
    };

    function selectSetor(value) {
        setValue(value);
        toggleSetor(value);
        qtde = 0;
    }

    function Setores(setor, s) {
        let nome = '';
        let tipoSetor = ''
        qtde = 0;
        if (pesquisar.valor === '') {
            qtde = lista[0].atendimentos
            nome = getNome(setor) + (setor === getSetor() ? ` -  (${qtde}) ` : '');
            tipoSetor = setor;
        } else {
            qtde = setor.atendimentos;
            nome = getNome(setor.tipo) + (` -  (${qtde}) `);
            tipoSetor = setor.tipo;
        }
        return <Tab key={s} value={tipoSetor} label={nome} style={{ textTransform: 'initial' }}
            id={`simple-tab-${s}`} aria-controls={`simple-tabpanel-${s}`} onClick={() => selectSetor(tipoSetor)}
        />
    }

    function TabSetores(tabsetor) {
        return <TabPanel key={tabsetor.tipo} value={value} index={tabsetor.tipo} sx={{ padding: '0 !important' }}>
            {tabsetor.tipo === value ? tabsetor.grupos.map(Grupos) : ''}
        </TabPanel>
    }

    function Grupos(grupo, g) {
        return <AccordionGrupos key={g} grupo={grupo.grupo} registros={grupo.total}  >
            {agruparTags(grupo.grupo) ? grupo.atendimentos.map(SubGrupos) :
                ordemSac === 'az' ? orderList(grupo.atendimentos, 'pessoa').map(ListaAtendimentos) : grupo.atendimentos.map(ListaAtendimentos)
            }
        </AccordionGrupos>
    }

    function SubGrupos(subGrupo, s) {
        if (listaLength(subGrupo.atendimentos) > 0)
            return <AccordionSubGrupos key={s} grupo={subGrupo.grpTag} registros={subGrupo.total} subGrupo={true}>
                {ordemSac === 'az' ? orderList(subGrupo.atendimentos, 'pessoa').map(ListaAtendimentos) : subGrupo.atendimentos.map(ListaAtendimentos)}
            </AccordionSubGrupos>
    }

    function ListaAtendimentos(atendimento) {
        return (getLayout() === 'padrao' || window.innerWidth < 768 ?
            <AccordionAtendimentos key={atendimento.codigo} codigo={codigo} showMenu={true}
                showMenuGeral={true} color={true} atendimento={atendimento} loading={!loading} />
            :
            <Paper key={atendimento.codigo} elevation={atendimento.codigo === codigo ? 10 : 0} style={{ background: 'inherit' }}>
                <Box style={colorSac(atendimento)} sx={colorDetail(atendimento, codigo)} p={1} mb={1}>
                    <HeaderSac codigo={atendimento.codigo} atendimento={atendimento} showMenuGeral={true} />
                </Box >
            </Paper>
        )
    }

    return (<TabContext value={value} >
        <Paper elevation={0} style={{ margin: '15px 10px 5px 10px' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons="auto" style={{ background: 'inherit', borderRadius: '15px' }}
                TabIndicatorProps={{ sx: { bgcolor: "#ed3237 !important", opacity: '1' } }}>
                {pesquisar.valor === '' ? getListSetores().map(Setores) : lista.map(Setores)}
            </TabList>
        </Paper>
        {getSetor() === 'TC' ?
            <>
                <Stack direction='row' sx={{
                    overflowX: 'scroll',
                    overflowY: 'hidden',
                    height: '92%'
                }}>
                    {listaLength(listSac[0].grupos) > 0 && listaLength(listMovimento) > 0 ? listSac[0].grupos.map(ListarColunas) : ''}
                </Stack>
                <SacDialog open={open} onClose={handleClose} />
            </>
            :
            <LayoutSac
                padrao={lista.map(TabSetores)}
                colL={lista.map(TabSetores)}
                colR={sac && codigo > 0 ?
                    <Box key={sac.codigo} p={1} >
                        {sac.codigo ?
                            <>
                                <HeaderSac codigo={codigo} fixedMenu={true} showMenu={true} showMenuGeral={true} disabledDestaque={true} />
                                <BodySac />
                            </>
                            : <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                style={{ paddingTop: '25%' }}
                            ><CircularProgress color='primary' /></Stack>}
                    </Box>
                    :
                    <img src='./app-assets/img/logo/Logo.svg' alt='logo redsis' style={{ height: heightScreen(-200), width: '100%', opacity: '0.1', WebkitFilter: 'grayscale(100%)', filter: 'grayscale(100%)' }} />
                }
            />
        }
    </TabContext >
    );
}
export function TabFaq() {
    const [value, setValue] = React.useState('d');
    const listTabs = [{ tipo: 'd', nome: 'Documentação' }, { tipo: 'v', nome: 'Versionamento' }, { tipo: 'p', nome: 'Pendentes' }];
    const { getFaqAprovados, getFaqPendentes, listFaqApro, listFaqPend, aprovFaq, deleteFaq, loading } = useFaq();
    const { getRastreios, rastreios } = useRastreios();
    const [faqSelect, setFaqSelect] = React.useState(iniFaq);
    const { } = useUsuarios();
    const [atualizar, setAtualizar] = React.useState(false);
    const [open, setOpen] = React.useState(false)
    const { listUsers } = React.useContext(GeralContext);

    function setElemento(faq) {
        if (faq.codigo === faqSelect.codigo) {
            document.getElementById('conteudo').innerHTML = '';
            setFaqSelect(iniFaq);
        }
        else {
            document.getElementById('conteudo').innerHTML = faq.descricao;
            setFaqSelect(faq)
        }
    }

    React.useEffect(() => {
        getRastreios();
        getFaqAprovados();
        getFaqPendentes();
        setAtualizar(false);
        setFaqSelect(iniFaq);
        document.getElementById('conteudo').innerHTML = '';
    }, [loading === false, atualizar === true])


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function selectTipo(value) {
        setValue(value);
        setElemento = '';
    }

    function aprovarFaq() {
        aprovFaq(faqSelect.codigo)
        setAtualizar(true);
    }

    function excluirFaq(codigo) {
        deleteFaq(codigo);
        setAtualizar(true);
    }

    function TabListTipo(tab, t) {
        let nome = '';
        let qtde = 0;

        qtde = value === 'p' && tab.tipo === value ? listaLength(listFaqPend) : listaLength(listFaqApro);
        if (tab.tipo === value)
            nome = tab.nome + (` -  (${qtde}) `);
        else
            nome = tab.nome;
        return <Tab key={t} value={tab.tipo} label={nome} style={{ textTransform: 'initial' }}
            id={`simple-tab-0`} aria-controls={`simple-tabpanel-0`} onClick={() => selectTipo(tab.tipo)} />
    }

    function TabTipo(tipo, t) {
        return <>
            <TabPanel key={t} value={value} index={tipo.tipo} sx={{ padding: '0 !important', marginBottom: '10px' }}>
                <Paper>
                    {value === 'p' && tipo.tipo === value && (listaLength(listFaqPend) > 0) ? listFaqPend.map(ListarFAQ) : ''}
                    {value === tipo.tipo && (listaLength(listFaqApro) > 0) ? listFaqApro.map(ListarFAQ) : ''}
                </Paper>
            </TabPanel >
        </>
    }

    function ListarFAQ(faq) {
        return <BoxFaq key={faq.codigo} codigoSelect={faqSelect.codigo} onClick={() => setElemento(faq)} faq={faq} />
    }

    function DetalhesFaq() {
        let listaRastreio = []
        listaRastreio.push(filtrarLista(rastreios, 'codigo', faqSelect.rastreio)[0]);
        for (let i = 0; i < listaLength(listaRastreio); i++) {
            if (listaRastreio[i].master > 0) {
                listaRastreio.push(filtrarLista(rastreios, 'codigo', listaRastreio[i].master)[0]);
            }
        }

        function ListDescrRastreio(r) {
            return <Stack key={r.codigo} direction='row' alignItems='center'>
                {r.master === 0 ? '' : <KeyboardArrowRightIcon color='iconColor' />}
                <TitleSmall title={r.descricao} />
            </Stack>
        }

        return <Box>
            <Grid container>
                <Grid item xs={12} md={10}>
                    <Title title={faqSelect.titulo} />
                    <Stack direction='row'>{listaLength(listaRastreio) > 0 ? listaRastreio.map(ListDescrRastreio).reverse() : ''}</Stack>
                    {faqSelect.criadopor ? <TitleSmall title={`Criado por ${filtrarLista(listUsers, 'codigo', faqSelect.criadopor)[0].login}`} /> : ''}
                    {faqSelect.aprovadopor ? <TitleSmall title={faqSelect.aprovadopor > 0 ? `Criado por ${filtrarLista(listUsers, 'codigo', faqSelect.aprovadopor)[0].login}` : ''} /> : ''}
                </Grid>
                <Grid item xs={12} md={2} style={{ textAlign: 'end' }}>
                    {getPerm('LI') ? <ButtonToolTip title='Aprovar' color='green' icon={<Thumb />} onClick={() => aprovarFaq()} /> : ''}
                    {getPerm('LI') || (faqSelect.criadopor === parseInt(getUser().sub)) ? <ButtonLinkIcon title='Editar' color='blue' icon={<EditIcon color='blue' />} to={`/faq/edit/${faqSelect.codigo}`} /> : ''}
                    {getPerm('LI') || (faqSelect.criadopor === parseInt(getUser().sub)) ? <ButtonToolTip title='Excluir' color='red' icon={<Delete />} onClick={() => excluirFaq(faqSelect.codigo)} /> : ''}
                    <ButtonToolTip title='Ampliar' color='iconColor' icon={<ZoomIcon />} onClick={() => setOpen(true)} />
                </Grid>
            </Grid>
        </Box >
    }

    return (<TabContext value={value} >
        <Paper p={0} sx={{ display: 'flex', alignItems: 'center', textAlign: 'right', width: '100%' }}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{ width: '100%' }}
            >
                <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons="auto" style={{ background: 'inherit', borderRadius: '15px' }}
                    TabIndicatorProps={{ sx: { bgcolor: "#ed3237 !important", opacity: '1' } }}>
                    {listTabs.map(TabListTipo)}
                </TabList>
                <ButtonLinkIcon title='Novo item' color='green' icon={<Add color='green' />} to='/faq/create' />
            </Stack>
        </Paper>
        <LayoutSac
            padrao={listTabs.map(TabTipo)}
            colL={listTabs.map(TabTipo)}
            colR={<Box p={1}>
                {faqSelect.codigo > 0 ?
                    <>
                        <DetalhesFaq />
                        <DividerBasic />
                    </> : ''}

                <div id='conteudo'
                    style={{
                        color: getTheme() === 'dark' ? '#f4f5fa' : '#343944',
                        padding: '10px',
                        marginTop: '10px',
                        fontSize: '12px'
                    }}>
                    <img src='./app-assets/img/logo/Logo.svg' alt='logo redsis' style={{ height: heightScreen(-200), width: '100%', opacity: '0.1', WebkitFilter: 'grayscale(100%)', filter: 'grayscale(100%)' }} />
                </div>
            </Box>
            }
        />
        <FAQDialog open={open} descricao={faqSelect.descricao} onClose={() => setOpen(false)} />
    </TabContext >
    );
}   