import * as React from 'react';
import { HeaderSac } from '../sac/header';
import { Box, Card, CardActions, Chip, Divider, Grid, Paper } from '@mui/material';
import { getTheme, heightDefault } from '../../utils/theme';
import { SubTitleSmall, TextConteudo, TitleGrupos, TitleSmall } from '../typography';
import { colorBorderKanban, colorFaq, coresAt, getNomeMovimento } from '../../utils/sac';
import { formatDateTime } from '../../utils/date';
import { GridCenter } from '../grid';
import { getNomeUsu } from '../../utils/user';
import { getTipo } from '../../utils/faq';
import { StackJustify } from '../stack';

export function BoxAtendimento(props) {
    return <Box key={props.atendimento.codigo} p={1} >
        <HeaderSac codigo={props.atendimento.codigo} showMenu={true}
            showMenuGeral={true} color={true} atendimento={props.atendimento} />
    </Box>
};

export function BoxAtendimentoTC(props) {
    const atendimento = props.atendimento;
    return (
        <Paper id={atendimento.codigo} elevation={4} style={{ borderRadius: '5px', marginBottom: '10px' }} draggable onDragStart={atendimento.status !== 'A' ? props.onDragStart : props.on} onClick={props.onClick} onDoubleClick={props.onDoubleClick}>
            <Card style={{ background: 'inherit', margin: '0px', padding: '5px', paddingTop: '0', borderLeft: colorBorderKanban(atendimento) }}>
                <div style={{ overflow: 'hidden', textAlign: 'justify', alignItems: 'center' }}>
                    {atendimento.status === 'A' ?
                        <Chip variant='outlined' label={getNomeMovimento(atendimento.movimento)} size='small' style={{ paddingTop: '1px', borderRadius: '5px', border: '0px', background: coresAt(atendimento), fontSize: '12px', fontWeight: '400 !important', color: 'white' }} />
                        : ''}
                    {atendimento.tags !== '' ?
                        <Chip variant='outlined' label={atendimento.tags} size='small' style={{ paddingTop: '1px', borderRadius: '5px', border: '0px', background: coresAt(atendimento), fontSize: '12px', fontWeight: '400 !important', color: 'white' }} />
                        : ''}
                    <TextConteudo text={atendimento.conteudo} />
                    <p />
                    <SubTitleSmall subTitle={formatDateTime(atendimento.status === 'A' ? atendimento.data_atendimento : atendimento.status === 'P' ? atendimento.data_abertura : atendimento.data_abertura)} />
                </div>
            </Card>
        </Paper>
    )
};

export function BoxGruposTC(props) {
    return (<Paper id={props.id} style={{ minWidth: '250px', background: 'inherit', margin: '10px', borderRadius: '5px' }} onDragStart={props.onDragStart} onDrop={props.onDrop} onDragOver={props.onDragOver} onMouseLeave={props.onMouseLeave}>
        <Box id={props.id} p={2}
            style={getTheme() === 'dark' ?
                { background: props.subGrupo ? "#343944" : '#262a34', borderRadius: '10px' } :
                { background: props.subGrupo ? "#f1f1f1" : '#f1f1f1', borderRadius: '10px' }
            }
        >
            {/* <div id={props.id} style={{ display: 'flex', alignItems: 'center', textAlign: 'justify', padding: '5px', width: '260px' }} onDragStart={props.onDragStart} onDrop={props.onDrop} onDragOver={props.onDragOver} onMouseLeave={props.onMouseLeave}> */}
            <StackJustify>
                <TitleGrupos title={props.grupo} />
                <TitleGrupos title={props.registros} />
            </StackJustify>
            {/* </div> */}
            <Divider />
            <p />
            <div id={props.id} style={{ overflow: 'auto', height: heightDefault(true) - 100 }}>
                {props.children}
            </div>
        </Box>
    </Paper >
    );
}

export function BoxSubGruposTC(props) {
    return (<Paper id={props.id} style={{ background: 'inherit', width: '100%' }} elevation={0}>
        <Box id={props.id} p={1}
            style={getTheme() === 'dark' ?
                { background: props.subGrupo ? "#343944" : '#262a34' } :
                { background: props.subGrupo ? "#f1f1f1" : '#f1f1f1' }
            }
        >
            <div id={props.id} style={{ textAlign: 'justify', width: '210px' }}>
                <StackJustify>
                    <TitleGrupos title={props.grupo} />
                    <TitleGrupos title={props.registros} />
                </StackJustify>
            </div>
            <Divider />
            <p />
            <div id={props.id} >
                {props.children}
            </div>
        </Box>
    </Paper >
    );
}


export function BoxFaq(props) {
    const faq = props.faq;
    return <Paper key={faq.codigo} elevation={props.codigoSelect === faq.codigo ? 9 : 0} sx={{ width: '100%' }}>
        <Box key={faq.codigo} onClick={props.onClick} mb={1} p={1} style={colorFaq()}>
            <TitleSmall title={faq.titulo} />
            <GridCenter>
                <Grid item xs={12} md={6}>
                    <SubTitleSmall subTitle={getTipo(faq.tipo)} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SubTitleSmall subTitle={getNomeUsu(faq.criadopor)} />
                </Grid>
            </GridCenter>
        </Box>
    </Paper>
};
